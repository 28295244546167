import { Translate } from 'next-translate';

export const removeFirstString = ({ string, stringToRemove }: { string?: string; stringToRemove: string }) => {
  if (string?.startsWith(stringToRemove)) {
    return string?.slice(stringToRemove.length);
  }
  return string;
};

export const getItemTranslate = <T extends Record<string, string>>(t: Translate, keysTranslate: T, values: string | string[]) => {
  if (Array.isArray(values)) {
    return values.map((value) => t(keysTranslate[value])).join(', ');
  }
  return t(keysTranslate[values]);
};
