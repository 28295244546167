import { ListItemSingle, TableListViewProps } from '@simetrikinc/desyk';
import { Translate } from 'next-translate';
import { ExecutionsMonitorQueryParams } from 'services/executionsMonitor/types';
import {
  generateItems,
  generateItemsWithTranslate,
  getItemsState,
  handleSearch,
  isApplyFilter,
  isApplyFilterDate,
  setStateFiltersUtils,
} from 'utils/filters';
import { Dispatch, SetStateAction } from 'react';
import { ExecutionsMonitorStatusLabels } from 'utils/tools/constants/enums';
import { StatusDotTypes } from 'utils/tools/constants/types';
import { FiltersRequest, stateFiltersDateType, stateFiltersType, FilterValue } from 'types/main/filters';
import { ColumnName, TriggerType } from './types';

export const getHeaders = (t: Translate): TableListViewProps['headers'] => [
  {
    label: t('executionsMonitor:listViewExecutionsMonitor.columns.id'),
    columnName: 'id',
  },
  {
    label: t('executionsMonitor:listViewExecutionsMonitor.columns.name'),
    columnName: 'name',
  },
  {
    label: t('executionsMonitor:listViewExecutionsMonitor.columns.trigger'),
    columnName: 'trigger',
    sortable: true,
    isFiltering: true,
  },
  {
    label: t('executionsMonitor:listViewExecutionsMonitor.columns.resource'),
    columnName: ColumnName.RESOURCE,
    sortable: true,
    isFiltering: true,
  },
  {
    label: t('executionsMonitor:listViewExecutionsMonitor.columns.end_date'),
    columnName: ColumnName.END_DATE,
    sortable: true,
    isFiltering: true,
  },
  {
    label: t('executionsMonitor:listViewExecutionsMonitor.columns.execution_state'),
    columnName: ColumnName.EXECUTION_STATUS,
    sortable: true,
    isFiltering: true,
  },
  {
    label: t('executionsMonitor:listViewExecutionsMonitor.columns.details'),
    columnName: 'details',
  },
];

export const getProcessingIds = (statuses: FiltersRequest[]): (string | number)[] => {
  return statuses
    .filter((item) =>
      [ExecutionsMonitorStatusLabels.RUNNING, ExecutionsMonitorStatusLabels.CREATED, ExecutionsMonitorStatusLabels.PENDING].includes(
        item.label as ExecutionsMonitorStatusLabels,
      ),
    )
    .map((item) => item.id);
};

export const formatStatusField = (t: Translate, statuses: FiltersRequest[]) =>
  [
    {
      label: t('executionsMonitor:listViewExecutionsMonitor.statusLabel.SUCCEEDED'),
      id: statuses.find((item) => item.label === ExecutionsMonitorStatusLabels.SUCCEEDED)?.id ?? '',
      statusDot: 'SUCCESS' as StatusDotTypes,
    },
    {
      label: t('executionsMonitor:listViewExecutionsMonitor.statusLabel.RUNNING'),
      id: getProcessingIds(statuses).length > 0 ? JSON.stringify(getProcessingIds(statuses)) : '',
      statusDot: 'OTHER' as StatusDotTypes,
    },
    {
      label: t('executionsMonitor:listViewExecutionsMonitor.statusLabel.FAILED'),
      id: statuses.find((item) => item.label === ExecutionsMonitorStatusLabels.FAILED)?.id ?? '',
      statusDot: 'ERROR' as StatusDotTypes,
    },
  ].filter((item) => item.id);

export const generateCurrentFilter = (
  t: Translate,
  filters:
    | {
        [key: string]: FiltersRequest[];
      }
    | undefined,
  stateFilters: stateFiltersType | undefined,
  stateFiltersDate: stateFiltersDateType,
  setStateFilters: Dispatch<SetStateAction<stateFiltersType | undefined>>,
): NonNullable<NonNullable<NonNullable<TableListViewProps['filtering']>['currentFilters']>> => {
  const triggerItems = filters?.triggers ? generateItemsWithTranslate(t, TriggerType, filters.triggers, stateFilters, ColumnName.TRIGGER) : [];
  return [
    {
      column: ColumnName.TRIGGER,
      dataFilter: {
        filterCardList: {
          searchInput: {
            value: stateFilters?.[ColumnName.TRIGGER]?.valueSearch ?? '',
            onChange: (value) => {
              handleSearch(stateFilters, ColumnName.TRIGGER, value, triggerItems, setStateFilters);
            },
          },
          header: {
            title: t('executionsMonitor:listViewExecutionsMonitor.filters.title1'),
          },
          items: getItemsState(stateFilters, ColumnName.TRIGGER, triggerItems) as ListItemSingle,
          onChange: (values: ListItemSingle) => {
            setStateFiltersUtils(stateFilters, ColumnName.TRIGGER, values as FilterValue[], setStateFilters);
          },
        },
      },
      applyFilter: isApplyFilter(stateFilters, ColumnName.TRIGGER),
    },
    {
      column: ColumnName.RESOURCE,
      dataFilter: {
        filterCardList: {
          searchInput: {
            value: stateFilters?.[ColumnName.RESOURCE]?.valueSearch ?? '',
            onChange: (value) => {
              handleSearch(stateFilters, ColumnName.RESOURCE, value, [], setStateFilters);
            },
          },
          header: {
            title: t('executionsMonitor:listViewExecutionsMonitor.filters.title2'),
          },
          items: filters?.resources ? [...generateItems(filters.resources, stateFilters, ColumnName.RESOURCE)] : [],
          onChange: (values: ListItemSingle) => {
            setStateFiltersUtils(stateFilters, ColumnName.RESOURCE, values as FilterValue[], setStateFilters);
          },
        },
      },
      applyFilter: isApplyFilter(stateFilters, ColumnName.RESOURCE),
    },
    {
      column: ColumnName.END_DATE,
      dataFilter: {
        dateFilter: {
          header: {
            title: t('executionsMonitor:listViewExecutionsMonitor.filters.title3'),
            showHeader: true,
          },
          ...stateFiltersDate,
        },
      },
      applyFilter: isApplyFilterDate(stateFiltersDate),
    },
    {
      column: ColumnName.EXECUTION_STATUS,
      dataFilter: {
        filterCardList: {
          header: {
            title: t('executionsMonitor:listViewExecutionsMonitor.filters.title4'),
          },
          items: filters?.statuses ? [...generateItems(formatStatusField(t, filters.statuses), stateFilters, ColumnName.EXECUTION_STATUS)] : [],
          onChange: (values: ListItemSingle) => {
            setStateFiltersUtils(stateFilters, ColumnName.EXECUTION_STATUS, values as FilterValue[], setStateFilters);
          },
        },
      },
      applyFilter: isApplyFilter(stateFilters, ColumnName.EXECUTION_STATUS),
    },
  ];
};

const parseValue = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return [String(value)];
  }
};

export const generateQueryParams = (stateFilters: stateFiltersType, stateFiltersDate: stateFiltersDateType): ExecutionsMonitorQueryParams => {
  const resultD = {
    trigger: stateFilters ? stateFilters[ColumnName.TRIGGER]?.selectedFilters?.map((item) => item) : undefined,
    resource_id: stateFilters ? stateFilters[ColumnName.RESOURCE]?.selectedFilters?.map((item) => item) : undefined,
    status: stateFilters ? stateFilters[ColumnName.EXECUTION_STATUS]?.selectedFilters?.flatMap((item) => parseValue(item)) : undefined,
    finished_at_to: stateFiltersDate?.date?.endDate ? [stateFiltersDate?.date?.endDate?.toISOString()] : undefined,
    finished_at_from: stateFiltersDate?.date?.startDate ? [stateFiltersDate?.date?.startDate?.toISOString()] : undefined,
  };
  return resultD;
};
