import useTranslation from 'next-translate/useTranslation';
import styles from './HelpData.module.scss';

function HelpData() {
  const { t } = useTranslation();

  const columns = [
    {
      id: 1,
      label: 'executionsMonitor:listViewExecutionsMonitor.columns.id',
      description: 'executionsMonitor:listViewExecutionsMonitor.help.columnsDescription.idColumn',
    },
    {
      id: 2,
      label: 'executionsMonitor:listViewExecutionsMonitor.columns.trigger',
      description: 'executionsMonitor:listViewExecutionsMonitor.help.columnsDescription.triggerColumn',
    },
    {
      id: 3,
      label: 'executionsMonitor:listViewExecutionsMonitor.columns.resource',
      description: 'executionsMonitor:listViewExecutionsMonitor.help.columnsDescription.resourceColumn',
    },
    {
      id: 4,
      label: 'executionsMonitor:listViewExecutionsMonitor.columns.end_date',
      description: 'executionsMonitor:listViewExecutionsMonitor.help.columnsDescription.endDateColumn',
    },
  ];

  return (
    <div className={styles.helpPanel}>
      <p>{t('executionsMonitor:listViewExecutionsMonitor.help.globalDescription')}</p>
      <p className={styles.helpPanel__subtitle}>{t('executionsMonitor:listViewExecutionsMonitor.help.subtitle')}</p>
      <ul className={styles.helpPanel__bullet}>
        {columns.map((column) => (
          <li key={column.id}>
            <p>
              <span>{t(column.label)}:</span>
              {t(column.description)}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HelpData;
