
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import MainLayout from 'components/layouts/MainLayout';
import { NextPageWithLayout } from 'types/main/page';
import ExecutionsMonitorListView from 'modules/executionsMonitor/listView/ExecutionsMonitorListView';
import { FilterProvider } from 'contexts/FilterContext/FilterContext';

const Dashboard: NextPageWithLayout = () => {
  return (
    <FilterProvider>
      <ExecutionsMonitorListView />
    </FilterProvider>
  );
};

Dashboard.getLayout = MainLayout('common:executionsMonitor');

 async function _getStaticProps() {
  return {
    props: {},
  };
}

export default Dashboard;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/executionsMonitor/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  