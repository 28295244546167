import { ExecutionsList } from './types';

export const EXECUTIONS_MONITOR_QUERY_KEY = 'executions_monitor_list_query';
export const EXECUTIONS_MONITOR_DETAIL_QUERY_KEY = 'executions_monitor_DETAIL_query';

export const DEFAULT_EXECUTIONS_MONITOR_LIST: ExecutionsList = {
  pagination_data: {
    currentPage: 1,
    totalItems: 0,
    totalPages: 1,
    totalItemsOnPage: 0,
    nextPage: '',
    previousPage: '',
  },
  results: [],
};
