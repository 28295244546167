import styles from './HelpInfo.module.scss';
import { HelpInfoProps } from './types';

const HelpInfo = ({ children, isHTML = false, title = '' }: HelpInfoProps) => {
  return (
    <div className={styles.wrapper}>
      {title && <h5 className={styles.title}>{title}</h5>}
      {!!isHTML && typeof children === 'string' ? (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: children }}
          className={styles.textContainer}
        />
      ) : (
        <p>{children}</p>
      )}
    </div>
  );
};

export default HelpInfo;
