import { ExportResourceList } from './types';

export const EXPORT_LIST_QUERY_KEY = 'destination_list_query';
export const ADVANCED_CONFIGURATION_CVS_QUERY_KEY = 'advanced_config_cvs';
export const DETAIL_EXPORT_QUERY_KEY = 'detail_export';
export const RESOURCE_TYPES_LIST_KEY = 'resource_types_list';
export const RESOURCE_LIST_KEY = 'resource_list';
export const TRIGGER_LIST_KEY = 'trigger_list';
export const EXPORT_RESOURCE_COLUMNS_QUERY_KEY = 'export_resource_columns';
export const RESOURCE_COLUMNS_QUERY_KEY = 'resource_columnn';
export const USERS_FILTER_QUERY_KEY = 'users_filter';

export const DEFAULT_EXPORT_LIST: ExportResourceList = {
  filters: {},
  pagination_data: {
    currentPage: 1,
    totalItems: 0,
    totalPages: 1,
    totalItemsOnPage: 0,
    nextPage: '',
    previousPage: '',
  },
  results: [],
};
