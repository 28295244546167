import { TextTag, joinClassName } from '@simetrikinc/desyk';
import useTranslation from 'next-translate/useTranslation';
import environmentConstant from 'utils/tools/environmentConstant';
import Image from 'next/image';
import styles from './withoutAddon.module.scss';

function WithoutAddon() {
  const { t } = useTranslation();
  const { staticsUrl: STATIC_URL } = environmentConstant;
  return (
    <div className={styles.wrapper} data-testid="WithoutAddon::wrapper">
      <div className={styles.firtsColumn}>
        <div className={styles.image}>
          <Image src={`${STATIC_URL}/images/intAppImage.svg`} alt="Select Workspace" width={500} height={176} />
        </div>
      </div>
      <div className={styles.secondColumn}>
        <div className={styles.containerHeader}>
          <TextTag type="purple">{t('home:withoutAddon.new')}</TextTag>
          <span className={styles.containerHeader__title}>{t('home:withoutAddon.title')}</span>
        </div>
        <p className={joinClassName([styles.secondColumn__paragraph, styles['secondColumn__paragraph--initial']])}>
          {t('home:withoutAddon.initialParagraph')}
        </p>
        <p className={styles.secondColumn__paragraph}>{t('home:withoutAddon.firstParagraph')}</p>
        <div className={styles.containerbullet}>
          <ul className={styles.containerbullet__bullet}>
            <li>{t('home:withoutAddon.bullet.firstParagraph')}</li>
            <li>{t('home:withoutAddon.bullet.secondParagraph')}</li>
            <li>{t('home:withoutAddon.bullet.thirdParagraph')}</li>
          </ul>
        </div>
        <p className={styles.secondColumn__paragraph}>{t('home:withoutAddon.secondParagraph')}</p>
      </div>
    </div>
  );
}

export default WithoutAddon;
