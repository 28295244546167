import { DestinationList } from './types';

export const DESTINATION_LIST_QUERY_KEY = 'destination_list_query';

export const DESTINATION_SUPPLIERS_QUERY_KEY = 'destination_suppliers_query';

export const DEFAULT_DESTINATION_LIST: DestinationList = {
  filters: {},
  pagination_data: {
    currentPage: 1,
    totalItems: 0,
    totalPages: 1,
    totalItemsOnPage: 0,
    nextPage: '',
    previousPage: '',
  },
  results: [],
};
