import useTranslation from 'next-translate/useTranslation';
import { Sidebar } from '@simetrikinc/desyk';

import { routes } from 'utils/tools/routes';
import Link from 'next/link';
import { SidebarProps } from './types';

const SidebarItemsMenu = ({ isConnection }: SidebarProps): JSX.Element => {
  const { t } = useTranslation();

  const sideBarMenuItems = [
    {
      label: t('common:executionsMonitor'),
      route: routes.executionsMonitor.list,
      icon: 'smtk-icon-executions-monitor',
      enabled: true,
      isDisabled: !isConnection,
    },
    {
      label: t('common:exports'),
      route: routes.exports.view,
      icon: 'smtk-icon-export',
      enabled: true,
      isDisabled: !isConnection,
    },
    {
      label: t('common:destinies'),
      route: routes.destinies.view,
      icon: 'smtk-icon-destinies',
      enabled: true,
    },
  ];

  const sideBarMenuItemsEnabled = sideBarMenuItems.filter(({ enabled }) => enabled);
  return <Sidebar itemMenuProps={sideBarMenuItemsEnabled} LinkWrapper={Link} />;
};

export default SidebarItemsMenu;
