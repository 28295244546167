import { useQueryClient } from 'react-query';
import { v4 as uuid4 } from 'uuid';
import environmentConstant from 'utils/tools/environmentConstant';
import { lazy, ReactElement, useEffect, useState } from 'react';
import { useUser, withPageAuthRequired } from '@auth0/nextjs-auth0/client';
import { Apps, HelpComponent, HelpProvider, RequestError, Roles, useDynamicRender, useErrorHandler, useWorkspace } from '@simetrikinc/desyk';
import WithoutAddon from 'components/WithoutAddon';
import { useGetDestinies } from 'services/destinies';
import useTranslation from 'next-translate/useTranslation';
import setLanguage from 'next-translate/setLanguage';
import { Router, useRouter } from 'next/router';
import InsufficientPermits from 'modules/insufficientPermits';
import { LayoutProps } from './types';
import SidebarItemsMenu from '../SidebarMenu/SideBarItemsMenu';
import LayoutHead from '../LayoutHead';
import styles from './MainLayout.module.scss';

const ValidationsMiddleware =
  typeof window !== 'undefined'
    ? lazy(() => {
        // eslint-disable-next-line import/no-unresolved
        return import('fe_middleware/validationsMiddleware');
      })
    : () => null;

function Layout({ children, title }: Readonly<LayoutProps>) {
  const { lang, t } = useTranslation();
  const [enabledGetDestinies, setEnabledGetDestinies] = useState(true);
  const router = useRouter();
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();
  const [isConnection, setIsConnection] = useState<boolean>(false);
  const { workspace } = useWorkspace();
  const { user } = useUser();
  const { versionApp } = environmentConstant;
  const versionNumber = versionApp?.split('.', 2).join('.') ?? '';
  const [resetChildren, setResetChildren] = useState(uuid4());

  const { hasPermissions } = useDynamicRender({ appId: Apps.INTEGRATION });
  const hasPermissionsUser = hasPermissions([Roles.SUPERVISOR]);

  useEffect(() => {
    (async () => queryClient.resetQueries())();
    setResetChildren(uuid4());
  }, [queryClient, workspace?.id]);

  useGetDestinies(
    {
      page: 1,
      page_size: 5,
    },
    {
      retry: false,
      onSuccess: ({ results }) => {
        setEnabledGetDestinies(false);
        if (results && results?.length > 0) {
          setIsConnection(true);
        }
        if (results?.length === 0 && !router.route.startsWith('/destinies')) {
          router.push('/destinies');
        }
      },
      onError: (err) => {
        setEnabledGetDestinies(false);
        handleError(err as RequestError);
      },
      enabled: enabledGetDestinies,
    },
  );

  return (
    <ValidationsMiddleware
      app={Apps.INTEGRATION}
      version={versionNumber}
      getAppView={WithoutAddon}
      locale={lang}
      pathname={router.pathname}
      setLanguage={setLanguage}
    >
      <HelpProvider events={['routeChangeComplete']} subscriber={Router.events.on} unsubscribe={Router.events.off}>
        <LayoutHead>
          <title>{`${t(title ?? '')} - ${workspace?.name} - Simetrik | ${user?.email}`}</title>
        </LayoutHead>
        <div className={styles.wrapper} data-testid="wrapper-Container-MainLayout">
          {hasPermissionsUser && (
            <>
              <SidebarItemsMenu key={`sidebar-${workspace?.id}`} isConnection={isConnection} />
              <div className={styles.wrapperContent} key={resetChildren}>
                {children}
              </div>
            </>
          )}
          {!hasPermissionsUser && <InsufficientPermits />}
          <HelpComponent />
        </div>
      </HelpProvider>
    </ValidationsMiddleware>
  );
}

const LayoutWithAuthRequired = withPageAuthRequired(Layout);

function MainLayout(title: string) {
  function NestedLayout(page: ReactElement) {
    return <LayoutWithAuthRequired title={title}> {page} </LayoutWithAuthRequired>;
  }

  return NestedLayout;
}

export { Layout };
export default MainLayout;
