import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './InsufficientPermits.module.scss';

function InsufficientPermits() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles['container-info']}>
        <h1 className={styles['container-info__title']}>{t('insufficientPermits:insufficientPermitsView.title')}</h1>
        <p className={styles['container-info__paragraph']}>{t('insufficientPermits:insufficientPermitsView.paragraphOne')}</p>
        <p className={styles['container-info__paragraph']}>{t('insufficientPermits:insufficientPermitsView.paragraphTwo')}</p>
        <p className={styles['container-info__paragraph']} style={{ marginTop: '1.5rem' }}>
          {t('insufficientPermits:insufficientPermitsView.paragraphThree')}
        </p>
      </div>
    </div>
  );
}

export default InsufficientPermits;
