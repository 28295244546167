import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, Pagination, RequestError, TableListView, TextTag, useDebounce, useErrorHandler, useHelpComponent } from '@simetrikinc/desyk';
import { CUSTOM_COLUMNS_WIDTH } from 'utils/tools/constants';
import ListHeader from 'modules/components/ListHeader';
import HelpTooltip from 'components/HelpTooltip';
import { ExecutionsList, ExecutionsMonitorListType } from 'services/executionsMonitor/types';
import { useGetExecutions } from 'services/executionsMonitor';
import { DEFAULT_EXECUTIONS_MONITOR_LIST } from 'services/executionsMonitor/constants';
import { useFilterUtils } from 'utils/filters';
import { useGetResourcesFiltersExecutionHistory } from '@/services/exports';
import { FiltersRequest } from '@/types/main/filters';
import { useFilterContext } from '@/contexts/FilterContext/FilterContext';
import { generateCurrentFilter, generateQueryParams, getHeaders } from './utils';
import styles from './ExecutionsMonitorListView.module.scss';
import HelpInfo from '../../../components/HelpInfo';
import ListRow from './components/ListRow';
import HelpData from '../components/HelpData';
import { ColumnName } from './types';

type FiltersTableListExports = {
  resources?: FiltersRequest[];
  triggers?: FiltersRequest[];
  statuses?: FiltersRequest[];
};

function ExecutionsMonitorListView() {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();
  const { currentPage, setCurrentPage } = useFilterContext();
  const [totalPages, setTotalPages] = useState(1);
  const [executionsData, setExecutionsData] = useState<ExecutionsList | undefined>(DEFAULT_EXECUTIONS_MONITOR_LIST);
  const [lastUpdated, setLastUpdated] = useState<string>(new Date().toLocaleString());
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [isErrorData, setIsErrorData] = useState<boolean>(false);
  const [filtersTableListExports, setFiltersTableListExports] = useState<FiltersTableListExports>({});
  const {
    handleApplyFilter,
    enableFilterButton,
    setEnableFilterButton,
    queryParamsState,
    setQueryParamsState,
    stateFiltersDate,
    setStateFiltersDate,
    stateFilters,
    setStateFilters,
  } = useFilterUtils(
    ColumnName.END_DATE,
    [ColumnName.END_DATE, ColumnName.EXECUTION_STATUS, ColumnName.RESOURCE, ColumnName.TRIGGER],
    generateQueryParams,
  );
  const stateFiltersDebounce = useDebounce(stateFilters, 500);

  const { data: dataFiltersResources } = useGetResourcesFiltersExecutionHistory(
    {
      search: stateFiltersDebounce && stateFiltersDebounce[ColumnName.RESOURCE]?.valueSearch,
    },
    { retry: false },
  );

  const { refetch, isFetching } = useGetExecutions(
    { ...queryParamsState, page: [currentPage], page_size: [30] },
    {
      retry: false,
      onSuccess: (_data) => {
        setTotalPages(_data.pagination_data.totalPages);
        setExecutionsData(_data);
        setIsLoadingData(false);
        setIsErrorData(false);
      },
      onError: (err) => {
        setIsLoadingData(false);
        setIsErrorData(true);
        handleError(err as RequestError);
      },
    },
  );

  const {
    results,
    pagination_data: { totalItems },
    filters,
  } = executionsData ?? DEFAULT_EXECUTIONS_MONITOR_LIST;

  useEffect(() => {
    const newFiltersTableListExports: FiltersTableListExports = {};

    if (dataFiltersResources) {
      newFiltersTableListExports.resources = dataFiltersResources.results;
    }

    if (filters) {
      newFiltersTableListExports.statuses = filters?.statuses;
      newFiltersTableListExports.triggers = filters?.triggers;
    }

    setFiltersTableListExports(newFiltersTableListExports);
  }, [dataFiltersResources, filters]);

  const { setHelpData } = useHelpComponent();
  const headers = getHeaders(t);

  const handleRefreshListExecutionsMonitor = async () => {
    setIsLoadingData(true);
    await refetch();
    setIsLoadingData(false);
    setLastUpdated(new Date().toLocaleString());
  };

  const currentFilters = generateCurrentFilter(t, filtersTableListExports, stateFilters, stateFiltersDate, setStateFilters);

  const handleDeleteFilter = () => {
    setStateFilters(undefined);
    setStateFiltersDate({ filter: undefined, date: { startDate: null, endDate: null } });
    setQueryParamsState(undefined);
    setEnableFilterButton(false);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => setCurrentPage(page);

  return (
    <div className={styles.wrapper}>
      <ListHeader>
        <ListHeader.Title>
          <div className={styles.header_mainTitle}>
            {t('executionsMonitor:listViewExecutionsMonitor.title')}
            <TextTag size="S">{t('executionsMonitor:listViewExecutionsMonitor.beta')}</TextTag>
            <div className={styles.header_helpTooltip}>
              <HelpTooltip
                data-testid="HelpTooltip:Button:HelpContainer"
                helpAction={() =>
                  setHelpData(
                    <HelpInfo isHTML title={t('executionsMonitor:listViewExecutionsMonitor.title')}>
                      <HelpData />
                    </HelpInfo>,
                  )
                }
              />
            </div>
          </div>
          <div className={styles.header_subTitle}>{t('executionsMonitor:listViewExecutionsMonitor.subtitle')}</div>
          <div className={styles.header_lastUpdate}>{`${t('executionsMonitor:listViewExecutionsMonitor.lastUpdate')} ${lastUpdated}`}</div>
        </ListHeader.Title>
        <ListHeader.RightWrapper>
          <Button
            id="refresh-button"
            icon={{
              start: { name: 'smtk-icon-arrow-rotate-right' },
            }}
            tooltip={{
              id: 'executions-monitor-list-view-refresh',
              description: t('common:buttons.update'),
              direction: 'bottom',
            }}
            buttonStyle="secondary"
            size="s"
            onClick={handleRefreshListExecutionsMonitor}
          />
          <Button
            id="delete-filter-button"
            icon={{
              start: { name: 'smtk-icon-filter-circle-xmark' },
            }}
            tooltip={{
              id: 'executions-remove-filters',
              description: t('common:buttons.removeFilters'),
              direction: 'bottom',
            }}
            buttonStyle="secondary"
            size="s"
            disabled={!enableFilterButton}
            onClick={handleDeleteFilter}
          />
        </ListHeader.RightWrapper>
      </ListHeader>
      <TableListView
        headers={headers}
        customColumnsWidth={CUSTOM_COLUMNS_WIDTH}
        isLoading={isLoadingData || isFetching}
        emptyMessage={{
          line_1: t('executionsMonitor:listViewExecutionsMonitor.empty.line1'),
          line_2: t('executionsMonitor:listViewExecutionsMonitor.empty.line2'),
        }}
        error={isErrorData}
        errorMessage={t('common:tableMessage.error')}
        emptyMessageWithFilters={{
          line_1: t('common:emptyMessageFilters.line1'),
          line_2: t('common:emptyMessageFilters.line2'),
        }}
        filtering={{
          currentFilters,
          onApplyFilter: handleApplyFilter,
        }}
        loaderRows={30}
      >
        {results?.map((execution: ExecutionsMonitorListType) => (
          <ListRow key={execution.id} execution={execution} />
        ))}
      </TableListView>
      <Pagination
        pagination={{
          amount: {
            totalPagesAmount: totalPages,
          },
          currentPage,
          disabled: totalPages === 0,
          changePage: handlePageChange,
        }}
        showPagination
        totalResources={{
          resources: totalItems,
          resourcesOnPage: results?.length ?? 0,
        }}
        showPageSelector
      />
    </div>
  );
}

export default ExecutionsMonitorListView;
