import React from 'react';
import styles from './ListHeader.module.sass';

interface ListHeaderProps {
  children: React.ReactNode | React.ReactNode[];
}

function ListHeader({ children }: ListHeaderProps) {
  return (
    <div className={styles.header} data-testid="ListHeader:wrapper">
      {children}
    </div>
  );
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <h1 className={styles['header--title']} data-testid="ListHeader:title">
      {children}
    </h1>
  );
}

function RightWrapper({ children }: { children: React.ReactNode }) {
  return <div className={styles['header--right-wrapper']}>{children}</div>;
}

ListHeader.Title = Title;
ListHeader.RightWrapper = RightWrapper;
export default ListHeader;
