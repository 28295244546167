import { UseQueryOptions, useQuery } from 'react-query';
import environmentConstant from 'utils/tools/environmentConstant';
import { useCallbackApi } from '@simetrikinc/desyk';
import { queryParamsToUrlSearchParams } from 'utils/filters';
import { DestinationList, DestinationQueryParams } from './types';
import { DESTINATION_LIST_QUERY_KEY } from './constants';
import { DestinationAdapter } from './adapters/Destination.adapter';

const { apiUrl: baseURL } = environmentConstant;

const conectionsPath = '/connections';

const useGetDestinies = (queryParams?: DestinationQueryParams, options?: UseQueryOptions<DestinationList>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<DestinationList>(
    [DESTINATION_LIST_QUERY_KEY, queryParams],
    async () => {
      const params = queryParamsToUrlSearchParams(queryParams);
      const response = await callbackApi<DestinationList>(`${conectionsPath}/`, {
        method: 'GET',
        baseURL,
        params,
      });
      return {
        ...response,
        results: DestinationAdapter(response.results || []),
      };
    },
    {
      ...options,
    },
  );
};

export { useGetDestinies };
