/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/require-default-props */
/* eslint react/jsx-props-no-spreading: 0 */
import { Icon, Tooltip } from '@simetrikinc/desyk';
import { noop } from 'underscore';
import styles from './HelpTooltip.module.scss';
import { HelpTooltipProps } from './types';

function HelpTooltip({ tooltipProps, helpAction }: Readonly<HelpTooltipProps>) {
  if (helpAction) {
    return (
      <div
        role="button"
        onClick={helpAction}
        tabIndex={-1}
        onKeyUp={noop}
        data-testid="HelpTooltip:Button:HelpContainer"
        className={styles['help-tooltip']}
      >
        <Icon name="smtk-icon-circle-question-solid" />
      </div>
    );
  }
  if (tooltipProps) {
    return (
      <div className={styles['help-tooltip']}>
        <Tooltip {...tooltipProps} size="m" direction={tooltipProps.direction}>
          <Icon name="smtk-icon-circle-question-solid" />
        </Tooltip>
      </div>
    );
  }
  return null;
}

export default HelpTooltip;
