import { UseQueryOptions, useQuery } from 'react-query';
import { useCallbackApi } from '@simetrikinc/desyk';
import environmentConstant from 'utils/tools/environmentConstant';
import { queryParamsToUrlSearchParams } from 'utils/filters';
import { QueryParams } from 'services/generalTypes';
import { ExecutionsDetail, ExecutionsList, ExecutionsMonitorQueryParams } from './types';
import { EXECUTIONS_MONITOR_DETAIL_QUERY_KEY, EXECUTIONS_MONITOR_QUERY_KEY } from './constants';
import { EXECUTIONS_MONITOR_LIST_PATH } from './service.routes';

const {
  remotes: { baseURL },
} = environmentConstant;

const useGetExecutions = (queryParams?: ExecutionsMonitorQueryParams, options?: UseQueryOptions<ExecutionsList>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<ExecutionsList>(
    [EXECUTIONS_MONITOR_QUERY_KEY, queryParams],
    async () => {
      const params = queryParamsToUrlSearchParams(queryParams);
      const response = await callbackApi<ExecutionsList>(`${EXECUTIONS_MONITOR_LIST_PATH}/`, { method: 'GET', baseURL, params });
      return response;
    },
    { ...options },
  );
};

const useGetExecutionsDetail = (id: string, queryParams?: QueryParams, options?: UseQueryOptions<ExecutionsDetail>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<ExecutionsDetail>(
    [EXECUTIONS_MONITOR_DETAIL_QUERY_KEY, queryParams],
    async () => {
      const path = `/export_action_execution_history/${id}/files/`;
      const response = await callbackApi<ExecutionsDetail>(path, { method: 'GET', baseURL, params: { ...queryParams } });
      return response;
    },
    { ...options },
  );
};

export { useGetExecutions, useGetExecutionsDetail };
