// eslint-disable-next-line import/no-unresolved
import { CustomItem, GroupCustomItem } from '@simetrikinc/desyk/dist/ProductComponents/Filters/FilterCardListItem/types';
import { Translate } from 'next-translate';
// eslint-disable-next-line import/no-unresolved
import { CurrentFilter, DateFilterAttr } from '@simetrikinc/desyk/dist/ProductComponents/TableListView/types';
import { useFilterContext } from 'contexts/FilterContext/FilterContext';
import { SetStateAction } from 'react';
import { stateFiltersDateType, stateFiltersType, FilterValue, ItemFilter, EnumType } from 'types/main/filters';
// eslint-disable-next-line import/no-unresolved
import { DateRange, FilterOptions } from '@simetrikinc/desyk/dist/Components/DatePicker/DatePickerFilter/types';

export const generateItems = (items: Array<ItemFilter>, state: stateFiltersType | undefined, columnName: string) => {
  return Object.values(items).map(({ label, id, statusDot }) => {
    const formattedItem = {
      label,
      value: String(id),
      isClicked: state ? state[columnName]?.selectedFilters?.includes(String(id)) : false,
      statusDot,
    };
    if (statusDot) {
      formattedItem.statusDot = statusDot;
    }
    return formattedItem;
  });
};

export const generateItemsWithTranslate = <T extends EnumType>(
  t: Translate,
  keysTranslate: T,
  items: Array<ItemFilter>,
  state: stateFiltersType | undefined,
  columnName: string,
) => {
  return Object.values(items).map(({ label, id, statusDot }) => {
    const formattedItem = {
      label: t(keysTranslate[label]),
      value: String(id),
      isClicked: state ? state[columnName]?.selectedFilters?.includes(String(id)) : false,
      statusDot,
    };
    if (statusDot) {
      formattedItem.statusDot = statusDot;
    }
    return formattedItem;
  });
};

export const setStateFiltersUtils = (
  state: stateFiltersType | undefined,
  columnName: string,
  values: FilterValue[],
  setState: (value: SetStateAction<stateFiltersType | undefined>) => void,
) => {
  setState({
    ...state,
    [columnName]: {
      ...(state?.[columnName] ?? { selectedFilters: [] }),
      selectedFilters: values.filter((item) => item.isClicked).map((item) => String(item.value)),
      itemsFilter: (state?.[columnName]?.itemsFilter || []).map((item: any) => {
        const valueItem = values.find((value) => value.value === item.value);
        return { ...item, isClicked: !!valueItem?.isClicked };
      }),
    },
  });
};

export const isApplyFilter = (state: stateFiltersType | undefined, columnName: string): boolean =>
  state ? !!state[columnName]?.selectedFilters.length : false;

export const isApplyFilterDate = (state: stateFiltersDateType | undefined): boolean =>
  state?.filter !== undefined && state?.filter !== null && state?.filter !== -1;

export const isEnableFilterButton = (state: stateFiltersType | undefined, columnNames: string[]): boolean => {
  let isEnable = false;
  if (state) {
    columnNames.forEach((column) => {
      if (state[column]?.selectedFilters.length > 0) {
        isEnable = true;
      }
    });
  }
  return isEnable;
};

export const queryParamsToUrlSearchParams = (queryParams: unknown) => {
  const params = new URLSearchParams();
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(key, item);
        });
      }
    });
  }
  return params;
};

export const getItemsState = (
  state: stateFiltersType | undefined,
  columnName: string,
  items: (CustomItem | GroupCustomItem)[],
): (CustomItem | GroupCustomItem)[] => {
  return state && (state[columnName]?.itemsFilter?.length ?? 0) > 0 ? state[columnName]?.itemsFilter ?? [] : items;
};

export const handleSearch = (
  state: stateFiltersType | undefined,
  columnName: string,
  value: string,
  items: (CustomItem | GroupCustomItem)[],
  setState: (value: SetStateAction<stateFiltersType | undefined>) => void,
) => {
  const lowerCaseQuery = value ? value.toLowerCase() : undefined;
  setState({
    ...state,
    [columnName]: {
      ...(state?.[columnName] ?? { selectedFilters: [] }),
      valueSearch: lowerCaseQuery,
      itemsFilter: lowerCaseQuery ? items.filter((item) => item.label?.toLowerCase().includes(lowerCaseQuery)) : undefined,
    },
  });
};

export const useFilterUtils = (dateColumnName: string, columnsFilters: string[], generateQueryParams: any) => {
  const {
    stateFiltersDate,
    setStateFiltersDate,
    setQueryParamsState,
    stateFilters,
    setEnableFilterButton,
    enableFilterButton,
    queryParamsState,
    setStateFilters,
    setCurrentPage,
  } = useFilterContext();

  function setToEndOfDay(date: Date | null) {
    if (!date) {
      return date;
    }
    const newDate = new Date(date);
    newDate.setUTCHours(23, 59, 59, 999);
    return newDate;
  }

  const formatDateStartAndEnd = (date: DateRange | undefined) => {
    if (!date) {
      return date;
    }
    if (!date?.endDate && date?.startDate) {
      const dateAux = { startDate: date.startDate, endDate: setToEndOfDay(date?.startDate) };
      return dateAux;
    }
    return { startDate: date.startDate, endDate: setToEndOfDay(date?.endDate) };
  };

  const updateDateFilterState = (dateFilter: DateFilterAttr | undefined) => {
    setStateFiltersDate({ filter: dateFilter?.filter, date: formatDateStartAndEnd(dateFilter?.date) });
  };

  const applyFilterWithNewDate = (dateFilter: DateFilterAttr | undefined) => {
    setQueryParamsState({
      ...generateQueryParams(stateFilters, {
        filter: dateFilter?.filter,
        date: formatDateStartAndEnd(dateFilter?.date),
      }),
    });
  };

  const applyDefaultFilter = () => {
    setQueryParamsState({
      ...generateQueryParams(stateFilters, {
        filter: stateFiltersDate.filter,
        date: formatDateStartAndEnd(stateFiltersDate.date),
      }),
    });
  };

  const isValidFilter = (filter: FilterOptions | undefined) => filter !== undefined && filter !== null && filter !== -1;

  const handleApplyFilter = ({ column, dataFilter }: CurrentFilter) => {
    const { dateFilter } = dataFilter;
    const isDateFilterValid = column === dateColumnName ? isValidFilter(dateFilter?.filter) : isValidFilter(stateFiltersDate?.filter);
    if (column === dateColumnName) {
      applyFilterWithNewDate(dateFilter);
      updateDateFilterState(dateFilter);
    } else {
      applyDefaultFilter();
    }
    setEnableFilterButton(isDateFilterValid ? true : isEnableFilterButton(stateFilters, columnsFilters));
    setCurrentPage(1);
  };

  return {
    stateFiltersDate,
    setStateFiltersDate,
    setQueryParamsState,
    stateFilters,
    setEnableFilterButton,
    enableFilterButton,
    queryParamsState,
    setStateFilters,
    updateDateFilterState,
    applyFilterWithNewDate,
    applyDefaultFilter,
    handleApplyFilter,
  };
};
