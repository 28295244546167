export const DestinationAdapter = (data: any[]) => {
  return data.map((item) => {
    return {
      id: item?.id,
      name: item?.name,
      provider: item?.provider,
      path: item?.path,
      dateCreated: item?.created_at,
      isEnabled: item?.status,
    };
  });
};
