export enum ExecutionsMonitorStatus {
  SUCCESS = 'SUCCESS',
  OTHER = 'OTHER',
  ERROR = 'ERROR',
}

export enum ExecutionsMonitorStatusLabels {
  CREATED = 'CREATED',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}
