const routes = {
  executionsMonitor: {
    list: '/executionsMonitor',
    detail: (id: string | number = '[id]') => `/executionsMonitor/${id}/detail`,
  },
  exports: {
    view: '/exports',
    add: '/exports/add',
    edit: (id: string | number = '[id]') => `/exports/${id}/edit`,
  },
  destinies: {
    view: '/destinies',
  },
  404: '/404',
};

export { routes };
