import { Cell, Button, Row, StatusDot, toast, Tooltip } from '@simetrikinc/desyk';
import { formatDateToUtc5 } from 'utils/tools/dates';
import { CUSTOM_COLUMNS_WIDTH } from 'utils/tools/constants';
import { ExecutionsMonitorListType } from 'services/executionsMonitor/types';
import useTranslation from 'next-translate/useTranslation';
import { copyToClipBoard } from 'utils/tools/clipboard';
import { ExecutionsMonitorStatus, ExecutionsMonitorStatusLabels } from 'utils/tools/constants/enums';
import { routes } from 'utils/tools/routes';
import { useRouter } from 'next/router';
import environmentConstant from 'utils/tools/environmentConstant';
import { getItemTranslate } from 'utils/tools/generalUtils';
import { ResourceType, TriggerType } from '../../types';
import styles from '../../ExecutionsMonitorListView.module.scss';

interface ListRowProps {
  execution: ExecutionsMonitorListType;
}

function ListRow({ execution }: ListRowProps) {
  const { t } = useTranslation('executionsMonitor');
  const router = useRouter();
  const executionState = execution.status;
  const resourceType = execution.resource.type;

  const copyId = () => {
    toast({
      id: 'copy-id-to-clipboard',
      title: t('listViewExecutionsMonitor.idCopied'),
      type: 'success',
      closeButton: true,
    });
    return copyToClipBoard(`${execution.id}`);
  };

  const resourceIcon = () => {
    if (resourceType === ResourceType.RECONCILIATION) {
      return 'smtk-icon-arrow-right-arrow-left';
    }
    if (resourceType === ResourceType.SOURCE_UNION) {
      return 'smtk-icon-databases';
    }
    if (resourceType === ResourceType.NATIVE) {
      return 'smtk-icon-database';
    }
    return 'smtk-icon-layer-group';
  };

  const statusDotLabel = () => {
    if (executionState === ExecutionsMonitorStatusLabels.SUCCEEDED) return t('listViewExecutionsMonitor.statusLabel.SUCCEEDED');
    if (
      executionState === ExecutionsMonitorStatusLabels.RUNNING ||
      executionState === ExecutionsMonitorStatusLabels.PENDING ||
      executionState === ExecutionsMonitorStatusLabels.CREATED
    )
      return t('listViewExecutionsMonitor.statusLabel.RUNNING');
    return t('listViewExecutionsMonitor.statusLabel.FAILED');
  };
  const statusDotCode = () => {
    if (executionState === ExecutionsMonitorStatusLabels.SUCCEEDED) return ExecutionsMonitorStatus.SUCCESS;
    if (
      executionState === ExecutionsMonitorStatusLabels.RUNNING ||
      executionState === ExecutionsMonitorStatusLabels.PENDING ||
      executionState === ExecutionsMonitorStatusLabels.CREATED
    )
      return ExecutionsMonitorStatus.OTHER;
    return ExecutionsMonitorStatus.ERROR;
  };

  const redirectResource = (url: string) => {
    const {
      remotes: { redirectUrl },
    } = environmentConstant;
    const path = resourceType === ResourceType.RECONCILIATION ? `/reconciliations/${url}/visualize` : `/sources/${url}`;
    const resourcePath = `${redirectUrl}${path}`;
    window.open(resourcePath);
  };

  const handleClick = () => {
    router.push(routes.executionsMonitor.detail(execution.id));
  };

  return (
    <Row customColumnsWidth={CUSTOM_COLUMNS_WIDTH} id={execution.id} hoverable={false}>
      <Cell className={styles.cell}>
        <Button
          label={`${execution.id}`}
          tooltip={{
            id: 'executions-monitor-list-view-copiId',
            description: t('common:buttons.copyId'),
            direction: 'top',
          }}
          buttonStyle="ghost"
          icon={{
            end: {
              name: 'smtk-icon-clone',
            },
          }}
          onClick={copyId}
        />
      </Cell>
      <Cell className={styles['header-row-hover']}>
        <Tooltip description={execution.export_name} direction="top" id={`smtk-exportName-tooltip-${execution.id}`} size="s">
          {execution.export_name}
        </Tooltip>
      </Cell>
      <Cell>{getItemTranslate(t, TriggerType, execution.trigger)}</Cell>
      <Cell className={styles.cell}>
        <Button
          label={`${execution.resource.id}`}
          buttonStyle="ghost"
          icon={{
            start: {
              name: resourceIcon(),
            },
            end: {
              name: 'smtk-icon-arrow-up-right-from-square',
            },
          }}
          onClick={() => redirectResource(`${execution.resource.id}`)}
        />
      </Cell>
      <Cell>
        {executionState === ExecutionsMonitorStatusLabels.RUNNING ||
        executionState === ExecutionsMonitorStatusLabels.CREATED ||
        executionState === ExecutionsMonitorStatusLabels.PENDING
          ? '-'
          : formatDateToUtc5(execution.finished_at as string)}
      </Cell>
      <Cell>
        <StatusDot label={statusDotLabel()} status={statusDotCode()} />
      </Cell>
      <Cell style={{ paddingLeft: '15px' }}>
        <Button
          disabled={execution.status !== ExecutionsMonitorStatusLabels.SUCCEEDED}
          buttonStyle="ghost"
          name="detail"
          icon={{
            start: {
              name: 'smtk-icon-ballot',
            },
          }}
          size="s"
          onClick={handleClick}
        />
      </Cell>
    </Row>
  );
}

export default ListRow;
