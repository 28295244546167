export const DATE_FORMAT = 'dd, MMMM yyyy';
export const QUERY_DATE_FORMAT = 'Y-MM-dd HH:mm:ss';
export const QUERY_DOWNLOAD_DATE_FORMAT = 'yyyy_MM_dd';

export const CUSTOM_COLUMNS_WIDTH = [
  {
    withFr: 0,
  },
  {
    withFr: 1,
  },
  {
    withFr: 1,
  },
  {
    withFr: 0.5,
  },
  {
    withFr: 1,
  },
  {
    withFr: 1,
  },
  {
    withFr: 0,
  },
];
