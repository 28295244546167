import { format as fnsFormat, subHours } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';

const locales = { es, en: enUS, pt: ptBR };

export const format = (date: number | Date, formatStr: string) => {
  // eslint-disable-next-line no-underscore-dangle
  const locale = locales[navigator.language as keyof typeof locales] || enUS;
  return fnsFormat(date, formatStr, { locale });
};

export const formatDateToUtc5 = (dateString: string, formatStr = "yyyy-MM-dd hh:mm a ('UTC'xxx)") => {
  if (!dateString) {
    return '-';
  }
  const date = new Date(dateString);
  const dateUser = new Date();
  const offsetInMinutes = dateUser.getTimezoneOffset();
  const offsetInHours = offsetInMinutes / 60;
  const utcMinus5Date = subHours(date, offsetInHours);
  const formattedDate = fnsFormat(utcMinus5Date, formatStr);
  return formattedDate;
};
