export enum ColumnName {
  TRIGGER = 'trigger',
  RESOURCE = 'resource_id',
  EXECUTION_STATUS = 'status',
  END_DATE = 'end_date',
}

export enum ResourceType {
  RECONCILIATION = 'RECONCILIATION',
  SOURCE_UNION = 'SOURCE_UNION',
  NATIVE = 'NATIVE',
  OPENING_RECORDS = 'OPENING_RECORDS',
}

export const TriggerType = {
  MANUALLY_BATCH_RECONCILED: 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerOne',
  'data-export-scheduled-exports': 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerTwo',
  MANUALLY_UNRECONCILED: 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerThree',
  ADJUSTED: 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerFour',
  'reconciliation-execution': 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerFive',
  RECONCILED: 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerSix',
  UNRECONCILED: 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerSeven',
  REVERT_ADJUSTED: 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerEight',
  MANUALLY_RECONCILED: 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerNine',
  'source-events': 'executionsMonitor:listViewExecutionsMonitor.triggers.triggerTen',
};
