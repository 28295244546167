// eslint-disable-next-line import/no-extraneous-dependencies
import copy from 'copy-to-clipboard';

export const copyToClipBoard = (text: string) => {
  try {
    copy(text);
    return Promise.resolve();
  } catch (error) {
    console.error('Error al copiar al portapapeles:', error);
    return Promise.reject(error);
  }
};
