import { UseMutationOptions, UseMutationResult, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { useCallbackApi } from '@simetrikinc/desyk';
import environmentConstant from 'utils/tools/environmentConstant';
import { queryParamsToUrlSearchParams } from 'utils/filters';
import { QueryParams } from 'services/generalTypes';
import { CreateExportParams, EditExportParams } from '@/modules/exports/components/addExport/contexts/types';
import {
  DETAIL_EXPORT_QUERY_KEY,
  EXPORT_LIST_QUERY_KEY,
  RESOURCE_COLUMNS_QUERY_KEY,
  RESOURCE_LIST_KEY,
  RESOURCE_TYPES_LIST_KEY,
  TRIGGER_LIST_KEY,
  USERS_FILTER_QUERY_KEY,
} from './constanst';
import { COLUMNS, EXPORT_ACTION_EXECUTION_HISTORY_LIST_PATH, EXPORT_LIST_PATH, FILTERS, RESOURCES, USERS } from './service.routes';
import {
  DetailExport,
  ExportQueryParams,
  ExportResource,
  ExportResourceList,
  filtersList,
  ResourceColumnsList,
  ResourceListParams,
  ResourceListResponse,
  ResourceTypesList,
  TriggerListResponse,
} from './types';

const {
  remotes: { baseURL },
} = environmentConstant;

interface DisableExportResourceParams {
  id: number;
  isEnabled: boolean | null;
}

const useGetExports = (queryParams?: ExportQueryParams, options?: UseQueryOptions<ExportResourceList>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<ExportResourceList>(
    [EXPORT_LIST_QUERY_KEY, queryParams],
    async () => {
      const params = queryParamsToUrlSearchParams(queryParams);
      const response = await callbackApi<ExportResourceList>(`${EXPORT_LIST_PATH}/`, {
        method: 'GET',
        baseURL,
        params,
      });
      return response;
    },
    {
      ...options,
    },
  );
};

const useGetResourcesFilters = (queryParams?: QueryParams, options?: UseQueryOptions<filtersList>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<filtersList>(
    [RESOURCE_COLUMNS_QUERY_KEY, queryParams],
    async () => {
      const path = `${EXPORT_LIST_PATH}${FILTERS}${RESOURCES}/`;
      const response = await callbackApi<filtersList>(path, { method: 'GET', baseURL, params: { ...queryParams } });
      return response;
    },
    { ...options },
  );
};

const useGetResourcesFiltersExecutionHistory = (queryParams?: QueryParams, options?: UseQueryOptions<filtersList>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<filtersList>(
    [RESOURCE_COLUMNS_QUERY_KEY, queryParams],
    async () => {
      const path = `${EXPORT_ACTION_EXECUTION_HISTORY_LIST_PATH}${FILTERS}${RESOURCES}/`;
      const response = await callbackApi<filtersList>(path, { method: 'GET', baseURL, params: { ...queryParams } });
      return response;
    },
    { ...options },
  );
};

const useGetListFiltersUsers = (queryParams?: QueryParams, options?: UseQueryOptions<filtersList>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<filtersList>(
    [USERS_FILTER_QUERY_KEY, queryParams],
    async () => {
      const response = await callbackApi<filtersList>(USERS, { method: 'GET', baseURL, params: { ...queryParams } });
      return response;
    },
    { ...options },
  );
};

const useDisableExportResource = (
  options?: UseMutationOptions<ExportResource, Error, DisableExportResourceParams>,
): UseMutationResult<ExportResource, Error, DisableExportResourceParams> => {
  const { callbackApi } = useCallbackApi();
  return useMutation<ExportResource, Error, DisableExportResourceParams>(async ({ id, isEnabled }) => {
    const response = await callbackApi<ExportResource>(`${EXPORT_LIST_PATH}/${id}/`, {
      method: 'PUT',
      baseURL,
      data: {
        export_resource_data: {
          is_enabled: !isEnabled,
        },
      },
    });
    return response;
  }, options);
};

const useGetDetailExport = (id: string, options?: UseQueryOptions<DetailExport>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<DetailExport>(
    [DETAIL_EXPORT_QUERY_KEY, id],
    async () => {
      const path = `/export_resources/${id}/`;
      const response = await callbackApi<DetailExport>(path, { method: 'GET', baseURL });
      return response;
    },
    {
      ...options,
    },
  );
};

const useGetResourceTypeList = (options?: UseQueryOptions<ResourceTypesList>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<ResourceTypesList>(
    [RESOURCE_TYPES_LIST_KEY],
    async () => {
      const path = `/resource_types_list/`;
      const response = await callbackApi<ResourceTypesList>(path, { method: 'GET', baseURL });
      return response;
    },
    { ...options },
  );
};

const useGetResourceColumns = (id: string, params?: ResourceListParams, options?: UseQueryOptions<ResourceColumnsList>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<ResourceColumnsList>(
    [RESOURCE_COLUMNS_QUERY_KEY, params],
    async () => {
      const path = `${COLUMNS}/${id}/`;
      const response = await callbackApi<ResourceColumnsList>(path, { method: 'GET', baseURL, params });
      return response;
    },
    { ...options },
  );
};

const useGetResourceList = (queryParams?: ResourceListParams, options?: UseQueryOptions<ResourceListResponse>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<ResourceListResponse>(
    [RESOURCE_LIST_KEY, queryParams],
    async () => {
      const path = `/resources_list/`;
      const response = await callbackApi<ResourceListResponse>(path, { method: 'GET', baseURL, params: { ...queryParams } });
      return response;
    },
    { ...options },
  );
};

const useGetTriggerList = (options?: UseQueryOptions<TriggerListResponse>) => {
  const { callbackApi } = useCallbackApi();
  return useQuery<TriggerListResponse>(
    [TRIGGER_LIST_KEY],
    async () => {
      const path = `/trigger_list/`;
      const response = await callbackApi<TriggerListResponse>(path, { method: 'GET', baseURL });
      return response;
    },
    { ...options },
  );
};

const useCreateExport = (
  options?: UseMutationOptions<ExportResource, Error, CreateExportParams>,
): UseMutationResult<ExportResource, Error, CreateExportParams> => {
  const { callbackApi } = useCallbackApi();
  return useMutation<ExportResource, Error, CreateExportParams>(async (params) => {
    const response = await callbackApi<ExportResource>(`${EXPORT_LIST_PATH}/`, {
      method: 'POST',
      baseURL,
      data: params,
    });
    return response;
  }, options);
};

const useEditExport = (
  id: string,
  options?: UseMutationOptions<ExportResource, Error, EditExportParams>,
): UseMutationResult<ExportResource, Error, EditExportParams> => {
  const { callbackApi } = useCallbackApi();
  return useMutation<ExportResource, Error, EditExportParams>(async (params) => {
    const response = await callbackApi<ExportResource>(`${EXPORT_LIST_PATH}/${id}/`, {
      method: 'PUT',
      baseURL,
      data: params,
    });
    return response;
  }, options);
};

export {
  useGetExports,
  useGetDetailExport,
  useGetResourceColumns,
  useGetListFiltersUsers,
  useGetResourcesFilters,
  useDisableExportResource,
  useGetResourceTypeList,
  useGetResourceList,
  useGetTriggerList,
  useCreateExport,
  useEditExport,
  useGetResourcesFiltersExecutionHistory,
};
